import React from "react"
import { useState, useEffect } from "react"

import { graphql, useStaticQuery } from "gatsby"

import "../styles/index.css"

import "bootstrap/dist/css/bootstrap.min.css"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

import { Navbar, Button } from "react-bootstrap"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faPhoneSquareAlt } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faRedo } from '@fortawesome/free-solid-svg-icons'

// import P5 from "../components/p5"

// import Footer from "../components/footer"
// import FooterPortraitPhone from "../components/footerPortraitPhone"

import BackgroundImage from "gatsby-background-image"

import Head from "../components/head"

// import { StaticImage } from "gatsby-plugin-image"

// export function Sand() {
//   return (
//     <StaticImage
//       src="../images/Summer-2021-Background-Sand copy.png"
//       alt="Sandy beach"
//       placeholder="tracedSVG"
//     />
//   )
// }

// export function SnowMen() {
//   return (
//     <StaticImage
//       src="../images/Summer-2021-Foreground-Snowmen.png"
//       alt="Snow men on beach"
//     />
//   )
// }

//Footer

export function Footer() { 
  return(
    <Navbar sticky="bottom"
    //  expand="lg"
    //  className="navbar-footer"
      style={{position: 'relative', padding: '0'}}
    >
        
      <div className='animationFooter centering-footer-title-and-copyright' 
      
      style={{position: 'absolute'}}>
        
            <a href="tel:416-781-5766">
              <Button 
                variant="outline-light" 
                className="footer-buttons-left" 
                >
                  <FontAwesomeIcon className="icon-size" icon={faPhoneSquareAlt} />
                      <span className="space-between-icon-and-text text-position">Phone </span>  
              </Button>   
            </a>

            <a href="mailto:vicenzas-crostoli@rogers.com">
              <Button 
                variant="outline-light" 
                className="footer-buttons-right"
                >
                  <FontAwesomeIcon className="icon-size" icon={faEnvelope} />
                    <span className="space-between-icon-and-text text-position">Email</span> 
              </Button>   
            </a>

        <p className="copyright">© {new Date().getFullYear()} Vicenza's Crostoli
        </p>  
        </div>
    </Navbar>
  )
}

//FooterPortraitPhone

// export function FooterPortraitPhone() {
//   return(
//     <Navbar sticky="bottom"
//       style={{position: 'relative', padding: '0'}}
//      >
//        <div className="message-position-and-color" style={{position: 'absolute', color: 'rgb(0, 100, 0)', paddingBottom: '2vh'}}>
//          <FontAwesomeIcon className="icon-size" icon={faRedo} />
//              <span className="text-size">Turn your phone to contact us </span>  
//         </div>
//      </Navbar>
//   )
// }


const Home = ({ landingPage }) => {
  const background = useStaticQuery(
    graphql`
      query {
        landscape: file(
          relativePath: { eq: "Vicenzas-Spring-Summer-Fall-2023_1B.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2400, quality: 50) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        portrait: file(
          relativePath: { eq: "Vicenzas-Spring-Summer-Fall-2023_2B.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2400, quality: 50) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  // const backgroundWinter = background.landscape.childImageSharp.fluid

  const backgroundWinter = [
    {
      ...background.landscape.childImageSharp.fluid,
      media: `screen and (orientation: landscape)`, 
    },
    {
      ...background.portrait.childImageSharp.fluid,
      media: `screen and (orientation: portrait)`,  
    }
  ]

  

  //The function below is the countdown timer to when customers can begin ordering for Easter.

  function getTimeRemaining() {
    const total = Date.parse("Nov 1, 2022") - Date.parse(new Date())
    const days = Math.ceil(total / (1000 * 60 * 60 * 24))
    const hours = Math.ceil((total / (1000 * 60 * 60)) % 24)

    return {
      days,
      hours,
    }
  }

  //The if statement below is to adjust the grammar of the sentence viewers see so that 1 "day" is not 1 "days"

  let timeDays
  let timeHours

  if (getTimeRemaining().days !== 1) {
    timeDays = "days"
  } else {
    timeDays = "day"
  }

  if (getTimeRemaining().hours !== 1) {
    timeHours = "hours"
  } else {
    timeHours = "hour"
  }

  {/* Using a workaround 'typeof' below because window is not recognized during SSR. Used explanation from github: https://github.com/gatsbyjs/gatsby/issues/12427. Also, the following website, among others, give an explanation https://dev.to/vvo/how-to-solve-window-is-not-defined-errors-in-react-and-next-js-5f97. Google "typeof window !== 'undefined'" for more information. */}

  let defaultHeight
  let defaultWidth

  if (typeof window !== 'undefined') {
    defaultHeight = window.innerHeight
    defaultWidth = window.innerWidth 
  }

  const [dimensions, setDimensions] = useState({
    windowHeight: defaultHeight,
    windowWidth: defaultWidth,
  })

  const [flip, setFlip] = useState(false)

  useEffect(() => {
    function handleResize() {
      setDimensions({
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth,
      })
      setFlip(!flip)
    }

    window.addEventListener("resize", handleResize)

    return _ => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <BackgroundImage
      className={landingPage}
      fluid={backgroundWinter}
      style={{
        backgroundPosition: "0% 50%",
        position: "relative",
        height: "100vh",
        width: "100vw",
        backgroundSize: "cover"
        // bottom: "-10vh",
        // bottom: "0vh",
      }}
    >
      <Head title="Home" />
      <div className="contain" style={{ position: "absolute", width: "100vw" }}>
        <Container fluid>
          <Row>
            <Col>
            <div className="gradient-text-title animationSnowmenAndText">
              <h1 className="title">
                Vicenza's Crostoli
              </h1>
            </div>  
                {/* <p className="text1 gradient-text-title animationSnowmenAndText ">Easter ordering begins on March 20th. */}
                <p className="text1 gradient-text-title animationSnowmenAndText ">...are available for Christmas and Easter only.
                </p>
                <p className="text2 gradient-text-title animationSnowmenAndText ">Christmas ordering begins on November 4th....
                {/* in  */}
                {/* {" "} 
                    {getTimeRemaining().days}{" "}
                    {timeDays}, */}
                </p>
                {/* <p className="text1 gradient-text-title animationSnowmenAndText gradient-text-body">
                  on November 1st.
                </p> */}
            </Col>
          </Row>
        </Container>
      </div>
      <div
        style={{ margin: "0", padding: "0", height: "100vh", bottom: "-1vh" }}
      >
      {/* <P5 id="sketch"></P5> */}
      </div>
        <Footer />
    </BackgroundImage>
  )
}

export default Home

